import React, { Component } from 'react';

import './Streams.css'
import { Conversation } from '../App';
import Video from './Video';

class Streams extends Component {
  isAnimating = false;
  liveContacts = [];

  state = {
    big: 'local',
    zoomed: false,
    stats: {}
  }
  
  updateVolumes() {
    if (!this.isAnimating) return;

    Conversation.getCallStats(this.props.conversation, (stats) => {
      this.props.conversation.contacts.forEach(c => {
        if (stats[c.id]) {
          const s = stats[c.id];

          if (s.local > 0 && s.local < 30) {
            if (s.local < 7) {
              this.props.optimizeVideo(c, 'local', s.local);
            }
            else if (s.local > 24) {
              this.props.optimizeVideo(c, 'local', s.local);
            }
          }
          if (s.remote > 0 && s.remote < 30) {
            if (s.remote < 7) {
              this.props.optimizeVideo(c, 'remote', s.remote);
            }
            else if (s.remote > 24) {
              this.props.optimizeVideo(c, 'remote', s.remote);
            }
          }
        }
      });

      requestAnimationFrame(() => {
        this.setState({stats: stats});
      });

      setTimeout(() => {
        requestAnimationFrame(() => this.updateVolumes());
      }, 2000);
    });
  }

  setBigScreen(kind) {
    this.setState({big: kind});
  }

  getStreamsOnline() {
    return this.props.remotes.map(r => r.stream).concat([this.props.localStream]).filter(s => {
      return s && s.active && s.getTracks().some(t => t.readyState === 'live');
    });
  }

  toggleZoom() {
    this.setState({
      zoomed: !this.state.zoomed
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const onlineStreams = this.getStreamsOnline();

    if (this.props.callStatus !== 'established' && onlineStreams.length > 1) {
      this.props.onStreamsRunning();
      if (!this.isAnimating) {
        this.isAnimating = true;
        setTimeout(() => this.updateVolumes(), 2000);
      }
    }
    else {
      if (this.getStreamsOnline().length < 2) {
        this.isAnimating = false;
      }
    }

    let mainScreen = this.state.big;
    const liveContacts = this.props.remotes.filter(r => r.stream && r.pc && r.stream.active && r.stream.getTracks().some(t => t.readyState === 'live'));

    if (mainScreen !== 'local' && !liveContacts.find(s => s.id === mainScreen)) {
      // mainScreen points to an invalid stream
      if (liveContacts.length === 1) {
        mainScreen = liveContacts[0].id;
      }
      else if (liveContacts.length === 0) {
        mainScreen = 'local';
      }

      if (mainScreen != this.state.big) {
        this.setState({big: mainScreen});
      }
    }
    else if (mainScreen === 'local' && liveContacts.length === 1) {
      if (this.liveContacts.length === 0) {
        mainScreen = liveContacts[0].id;

        if (mainScreen != this.state.big) {
          this.setState({big: mainScreen});
        }
      }
    }

    this.liveContacts = liveContacts;
  }

  componentWillUnmount() {
    this.isAnimating = false;
  }

  render() {
    let mainScreen = this.state.big;
    const liveStreams = this.props.remotes.filter(r => r.stream && r.pc && r.stream.active && r.stream.getTracks().some(t => t.readyState === 'live'));
    const streams = liveStreams.map(r => {
      const isBigStream = (mainScreen === r.id);
      return (
        <div
          key={r.id}
          className={"remoteStreamContainer" + (isBigStream ? ' bigStream' : ' smallStream')}
          onClick={() => mainScreen === r.id ? this.toggleZoom() : this.setBigScreen(r.id)}
          style={isBigStream ? {} : {
            maxWidth: `${20 + (this.state.stats[r.id] || {}).volume * 35}vh`,
            maxHeight: `${20 + (this.state.stats[r.id] || {}).volume * 35}vw`,
            backgroundColor: 'black'
          }}
        >
          <div className="contactname"><span>{r.name}</span></div>
          <Video muted={true} className="remoteStream" stream={r.stream} onError={() => this.props.onError('remote', r)} />
        </div>
      );
    });

    return (
      <div className={
          "streams"
          + (this.props.remoteStream ? ' hasremotestream' : '')
          + (this.props.localStream ? ' haslocalstream' : '')
          + (this.state.zoomed ? ' zoomed' : '')
        }>
          {streams}
          {mainScreen && this.props.localStream &&
            <div className={"localStreamContainer" + (mainScreen === 'local' ? ' bigStream' : ' smallStream')}
            onClick={() => mainScreen === 'local' ? this.toggleZoom() : this.setBigScreen('local')}
          >
            <div className="contactname"><span>{this.props.local.name}</span></div>
            <Video className="localStream" stream={this.props.localStream} muted={true} onError={() => this.props.onError('local')} />
          </div>}
      </div>
    )
  }
};

export default Streams;